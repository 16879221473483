header {
	position: absolute;
	width: 100%;
	margin: auto;
	z-index: 999;
	.logo {
		width: 225px;
		padding: 35px 0;
		@media only screen and (max-width: 500px) {
			width: 100%;
			padding: 100px 0;
			text-align: center;
		}

		img {
			width: 100%;
			@media only screen and (max-width: 500px) {
				width: 200px;
			}
		}
	}	
}