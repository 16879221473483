@-webkit-keyframes glitch {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    20% {
        -webkit-transform: translate(-3px, 3px);
        transform: translate(-3px, 3px);
    }

    40% {
        -webkit-transform: translate(-3px, -3px);
        transform: translate(-3px, -3px);
    }

    60% {
        -webkit-transform: translate(3px, 3px);
        transform: translate(3px, 3px);
    }

    80% {
        -webkit-transform: translate(3px, -3px);
        transform: translate(3px, -3px);
    }

    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes glitch {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    20% {
        -webkit-transform: translate(-3px, 3px);
        transform: translate(-3px, 3px);
    }

    40% {
        -webkit-transform: translate(-3px, -3px);
        transform: translate(-3px, -3px);
    }

    60% {
        -webkit-transform: translate(3px, 3px);
        transform: translate(3px, 3px);
    }

    80% {
        -webkit-transform: translate(3px, -3px);
        transform: translate(3px, -3px);
    }

    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}