
:root {
	// colors
	--color-white: #fff;
	--color-blue: #2ddfaf;
	// --color-red: #d21f50;
	--color-red: #df2d5d;
	--color-dark: #03222e;
	--color-grey: #2d4651;
	// Fonts
	--font-financier-regular: 'FinancierRegular';
}