// Landing Section
section.landing {
	height: 100vh;
	background-color: #000;
	overflow: hidden;

	.video-bg {
		position: absolute;
		// right: -200px;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
		object-fit: cover;
	}

	// Navigation
	nav {
		position: absolute;
		width: 30px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-left: 25px;
		text-transform: uppercase;
		z-index: 999;

		@media only screen and (max-width: 500px) {
			width: 100%;
			margin: 0;
			flex-direction: column;
		}

		.rotate-up {
			writing-mode: vertical-lr;
			text-orientation: sideways-right;
			transform: rotate(180deg);
			font-size: 15px;
			letter-spacing: 1px;

			@media only screen and (max-width: 500px) {
				writing-mode: inherit;
				text-orientation: inherit;
				transform: rotate(0deg);
			}

		}

		ul {
			margin-top: 35px;
			display: flex;
			flex-direction: column;
			align-items: center;

			@media only screen and (max-width: 500px) {
				flex-direction: row;
			}


			li {
				@media only screen and (max-width: 500px) {
					margin-bottom: 0px;
					margin-right: 10px;
				}

				margin-bottom: 20px;

				&.line {
					height: 35px;
					width: 2px;
					background: #fff;

					@media only screen and (max-width: 500px) {
						height: 2px;
						width: 20px;
						order: -2;
					}
				}

				&.rotate-up {
					color: #fff;

					@media only screen and (max-width: 500px) {
						order: -3;
						font-size: 14px;
					}
				}
			}
		}

		.cta {
			margin-bottom: 35px;

			@media only screen and (max-width: 500px) {
				margin-bottom: 115px;
			}

			a {
				display: inline-block;
			}
		}
	}

	// Content
	.content {
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99;
		padding-top: 25px;

		h1 {
			@media only screen and (max-width: 500px) {
				text-align: center;
			}

			span {
				display: block;
				cursor: default;
			}

			.first-glitch {
				font-size: clamp(90px, 20vw, 160px);
				line-height: clamp(90px, 20vw, 150px);
				color: var(--color-blue);
				position: relative;
				margin: 0;
				opacity: 0.8;

				&::before,
				&::after {
					display: block;
					content: attr(data-glitch);
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					opacity: 1;
				}

				// &::after {
				// 	color: #f0f;
				// 	z-index: -2;
				// 	-webkit-animation: glitch 10s cubic-bezier(0.25, 0.46, 0.45, 0.80) both inherit;
				// 	animation: glitch 10s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
				// }

				&::before {
					// color: #0ff;
					color: #fff;
					z-index: -1;
					animation: glitch 10s cubic-bezier(0.25, 0.46, 0.45, 0.80) reverse both infinite;
				}
			}

			.second-glitch {
				font-size: clamp(45px, 12vw, 130px);
				line-height: clamp(45px, 12vw, 120px);
				color: var(--color-white);
				font-family: var(--font-financier-regular);
				position: relative;
				margin: 0;
				opacity: 0.8;

				&::before,
				&::after {
					display: block;
					content: attr(data-glitch);
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					opacity: 1;
				}

				// &::after {
				// 	color: #f0f;
				// 	z-index: -2;
				// 	-webkit-animation: glitch 10s cubic-bezier(0.25, 0.46, 0.45, 0.80) both inherit;
				// 	animation: glitch 10s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
				// }

				&::before {
					// color: #0ff;
					color: var(--color-blue);
					z-index: -1;
					animation: glitch 10s cubic-bezier(0.25, 0.46, 0.45, 0.80) reverse both infinite;
				}
			}
		}

		.scroll-indicator {
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			text-align: center;
			margin-bottom: 35px;

			&>* {
				display: inline-block;
				line-height: 18px;
				font-size: 13px;
				font-weight: normal;
				color: #ffffff;
				letter-spacing: 2px;
			}

			.mouse {
				position: relative;
				display: block;
				width: 30px;
				height: 45px;
				margin: 0 auto 20px;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				border: 3px solid rgba(255, 255, 255, 0.8);
				border-radius: 23px;

				&>* {
					position: absolute;
					display: block;
					top: 29%;
					left: 50%;
					width: 6px;
					height: 6px;
					margin: -3px 0 0 -3px;
					background: rgba(255, 255, 255, 0.8);
					border-radius: 50%;
					-webkit-animation: ani-mouse 2.5s linear infinite;
					-moz-animation: ani-mouse 2.5s linear infinite;
					animation: ani-mouse 2.5s linear infinite;
				}

				@keyframes ani-mouse {
					0% {
						opacity: 1;
						top: 29%;
					}

					15% {
						opacity: 1;
						top: 50%;
					}

					50% {
						opacity: 0;
						top: 50%;
					}

					100% {
						opacity: 0;
						top: 29%;
					}
				}
			}
		}
	}
}

// Section About
section.about {
	position: relative;
	// height: 1250px;
	height: 1150px;
	background-image: url("../images/bg.jpg");
	background-attachment: fixed;
	background-size: cover;
	background-position: center;
	overflow: hidden;
	display: flex;
	align-items: flex-end;

	@media only screen and (max-width: 400px) {
		height: 1250px;
	}

	@media only screen and (max-width: 1100px) {
		height: 1400px;
	}

	@media only screen and (max-width: 1100px) {
		height: 1550px;
	}

	.sm-circle {
		position: absolute;
		overflow: hidden;
		width: 1350px;
		height: 1350px;
		background: #fff;
		border-radius: 50%;
		left: 50%;
		transform: translateX(-50%);
		top: -867px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		z-index: 2;

		@media only screen and (max-width: 400px) {
			height: 1450px;
		}


		.about-us {
			height: 460px;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 60%;

			@media only screen and (max-width: 900px) {
				width: 90vw;
			}

			@media only screen and (max-width: 400px) {
				height: 570px;
			}



			.content {
				display: flex;
				margin-top: 100px;

				@media only screen and (max-width: 900px) {
					margin-top: 50px;
					flex-direction: column;
					align-items: center;
					width: 90vw;
				}
			}

			.btn-group {
				display: flex;

				@media only screen and (max-width: 400px) {
					flex-direction: column;
					margin-left: 0;
				}

				.btn {
					cursor: pointer;
					outline: none;
					border: none;
					color: #fff;
					padding: 7px 20px;
					font-size: 20px;
					text-transform: uppercase;
					margin-top: 30px;
					border-radius: 3px;
					margin-left: 25px;

					@media only screen and (max-width: 400px) {
						margin-left: 0;
					}

					&.btn-red {
						background-color: var(--color-red);
					}

					&.btn-blue {
						background-color: #22a985;
					}

				}
			}

			.title {
				h3 {
					font-size: clamp(20px, 10vw, 35px);
					color: var(--color-grey);
				}

				h2 {
					font-size: clamp(50px, 15vw, 75px);
					color: var(--color-dark);

					span {
						color: var(--color-blue);
					}
				}
				p.pron{
					color: var(--color-red);
					opacity: 0.8;
					font-size: 25px;
					font-weight: bold;
					text-align: right;
					margin-right: 15px;
					font-family: 'FinancierBold' !important;

				}
			}

			.description {
				margin-left: 85px;

				@media only screen and (max-width: 900px) {
					margin: 30px 20px 0;
					text-align: center;
				}

				p {
					color: #000;
					line-height: 24px;
					font-size: 18px;
				}

			}
		}
	}

	.big-circle-overlay {
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 1350px;
		border-radius: 50%;
		left: 50%;
		transform: translateX(-50%);
		top: -671px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		z-index: 100;
		// background-image: url("../images/bg.jpg");
		background: orange;
		background-attachment: fixed;

		// display: flex;
		// align-items: flex-end;
	}

	.big-circle {
		position: absolute;
		width: 1750px;
		height: 1600px;
		// background: var(--color-red);
		// border-radius: 50%;
		// left: 50%;
		// transform: translateX(-50%);
		// top: -100px;
		// overflow: hidden;
		// z-index: 15;

		height: 100%;
		width: 100vw;
		top: 15vh;


		img {
			// height: 100%;
			// width: 100%;
			background-size: cover;
		}
	}

	.our-process {
		background: var(--color-red);
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 99;
		padding: 80px 0;
		width: 100%;

		.content {
			width: 100%;

			.title {
				text-align: center;
				font-size: 130px;
				color: #fff;
				margin-bottom: 40px;

				span {
					color: var(--color-blue);
				}
			}

			.process.sm {
				display: none;

				.item {
					margin-right: 0px;
				}

				@media only screen and (max-width: 1100px) {
					display: flex !important;
				}
			}




			.process {
				display: flex;
				justify-content: center;

				@media only screen and (max-width: 700px) {
					flex-direction: column;
				}

				// flex-direction: column;

				.item {
					// width: 33.33%;
					text-align: center;
					box-sizing: border-box;
					margin-right: 100px;
					margin-bottom: 50px;

					@media only screen and (max-width: 700px) {
						margin-right: 0px;
					}

					@media only screen and (max-width: 1100px) {
						&:nth-child(2) {
							margin-right: 0px;
						}
					}

					&.last {
						margin-right: 0px;

						@media only screen and (max-width: 1100px) {
							display: none;
						}
					}

					h2 {
						font-size: 35px;
						color: #fff;
						display: inline-block;
						padding-top: 15px;
						border-top: 4px solid var(--color-blue);
					}

					ul {
						margin-top: 30px;

						li {
							color: #fff;
							margin-top: 10px;
							line-height: 22px;
						}
					}
				}
			}
		}
	}
}

// Section Poftfolio
section.portfolio {
	overflow: hidden;

	.card-group {
		display: flex;
		flex-wrap: wrap;

		.cards {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50%;
			height: 400px;

			@media only screen and (max-width: 800px) {
				width: 100%;
			}

			:hover {
				&.image-wrapper .play {
					width: 60px;
					height: 60px;
				}
			}

			.image-wrapper {
				width: 100%;
				height: 100%;
				position: relative;

				.play {
					pointer-events: none;
					cursor: pointer;
					content: "";
					position: absolute;
					background: #fff;
					background-size: cover;
					border-radius: 50%;
					width: 50px;
					height: 50px;
					top: 50%;
					left: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					transform: translate(-50%, -50%);
					z-index: 99;
					transition: all 200ms ease-in-out;

					i {
						color: var(--color-red);
						font-size: 20px;
						margin-left: 4px;
						margin-top: 1px;
					}
				}

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					opacity: 0.8;
				}


			}


			&.identifier {
				background: var(--color-blue);

				h2 {
					position: relative;
					font-size: clamp(100px, 13vw, 120px);

					@media only screen and (max-width: 800px) {
						font-size: clamp(80px, 20vw, 120px);
					}

					color: var(--color-dark);

					.dot {
						color: var(--color-red);
					}

					.sub {
						position: absolute;
						top: -35px;
						right: -30px;
						font-size: clamp(40px, 8vw, 60px);
						color: var(--color-grey);
						font-family: 'FinancierMedium';

						@media only screen and (max-width: 800px) {
							font-size: clamp(40px, 5vw, 50px);
						}
					}
				}
			}
		}
	}
}

// Section Clients
section.client {
	.content {
		padding: 100px 0;

		.title {
			text-align: center;
			margin-bottom: 80px;

			h2 {
				font-size: clamp(60px, 15vw, 130px);
				color: var(--color-dark);

				span {
					color: var(--color-red);
				}
			}

			p {
				font-size: clamp(16px, 5vw, 25px);
				font-weight: 500;
				color: var(--color-light);

			}
		}

		.clients {
			.swiper-container {
				.swiper-wrapper {
					display: flex;
					align-items: center;

					.swiper-slide {
						display: flex;
						align-items: center;
						justify-content: center;

						.image-wrapper {
							height: 70px;
							display: flex;
							justify-content: center;
							align-items: center;

							img {
								filter: grayscale(100%);
								height: 100%;

								// width: 100%;
								&:hover {
									// filter: grayscale(0%);

								}
							}
						}
					}

				}

			}
		}

		.testimonials {
			margin-top: 100px;

			.swiper-container-two {
				overflow: hidden;

				.swiper-wrapper {
					display: flex;
					// align-items: center;

					.swiper-slide {
						display: flex;
						align-items: center;
						justify-content: center;

						.testimonial-wrapper {
							width: 80%;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;

							p {
								text-align: center;
								font-size: 20px;
								line-height: 28px;
								font-family: 'FinancierMedium';
								margin-bottom: 30px;
								color: var(--color-dark);
							}

							h3 {
								color: var(--color-red);
								font-family: 'FinancierBold' !important;
								font-size: 22px;
								text-align: center;
							}
						}
					}

				}

			}
		}
	}
}

// Scroll Top
.scroll-top {
	position: fixed;
	right: 20px;
	bottom: 50px;
	z-index: 99999999;

	img {
		width: 70px;
	}

	@media only screen and (max-width: 700px) {
		img {
			width: 50px !important;
		}

	}
}