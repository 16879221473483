html {
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
	font-family: 'Montserrat', sans-serif !important;
}

a {
	text-decoration: none;
	color: #fff;
}

section{
	overflow-x: hidden;
}