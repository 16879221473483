@font-face {
	font-family: 'Financier';
	src: url('../../fonts/Financier/FinancierDisplay-Black.otf') format('TrueType');
}

@font-face {
	font-family: 'FinancierBold';
	src: url('../../fonts/Financier/FinancierDisplay-Bold.otf') format('TrueType');
}

@font-face {
	font-family: 'FinancierMedium';
	src: url('../../fonts/Financier/FinancierDisplay-Medium.otf') format('TrueType');
}

@font-face {
	font-family: 'FinancierRegular';
	src: url('../../fonts/Financier/FinancierDisplay-Regular.otf') format('TrueType');
}

// 
h1, h2, button{
	font-family: 'FinancierBold' !important;	
	font-weight: 900;
}
h3{
	font-family: 'FinancierMedium' !important;
}