footer {
	background: url('../../images/texture.jpg');
	background-size: contain;
	background-attachment: fixed;

	.details {
		padding-top: 50px;
		display: flex;
		justify-content: space-around;

		@media only screen and (max-width: 750px) {
			flex-direction: column;

		}

		.details__contact {
			ul {
				a:first-child {
					margin: 0 !important;
					padding-bottom: 15px !important;
				}
			}
		}

		.details__address {
			@media only screen and (max-width: 750px) {
				margin-top: 30px;

			}
		}

		.details__contact,
		.details__address {
			display: flex;
			flex-direction: column;
			align-items: center;

			p {
				color: #fff;
				font-size: 24px;
				text-transform: uppercase;
				color: var(--color-blue);
				font-weight: bold;
				margin-bottom: 20px;
			}

			ul {
				text-align: center;

				a,
				li {
					font-size: 18px;
					font-weight: 500;
					display: block;
					color: #fff;
				}

				li {
					margin-bottom: 20px;
				}

				a:first-child {
					margin-bottom: 20px;
				}

				a:last-child {
					margin-top: -15px !important;
				}

				li span {
					display: block;
					margin-top: 5px;
				}
			}
		}
	}

	.socials {
		margin-top: 10px;

		@media only screen and (max-width: 750px) {
			margin-top: 25px;

		}

		ul {
			display: flex;
			justify-content: center;

			a {
				font-size: 24px;
				margin-right: 30px;

				&:last-child {
					margin: 0;
				}
			}
		}
	}

	.copyright {
		padding-bottom: 30px;
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 90px;
		}

		p {
			margin-left: 10px;
			color: #fff;
		}
	}
}